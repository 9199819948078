import { useEffect, useState } from 'react';
import { smoothScroll, smoothScrollToTopOfFilters } from '../../util/PageUtil';
import dynamic from 'next/dynamic';
import {
  addQueryParamWithoutRedirecting,
  isEmpty,
  removeQueryParam,
} from '../../util/DataUtil';
import { useRouter } from 'next/router';
import { getContinentColorByName } from '../../util/ColorUtil';

const ResourceSearch = dynamic(() => import('./resource-search'));

export default function ResourceFilter({
  hideFilter,
  data,
  title,
  search,
  triggerFilter,
  onSearchChange,
  handleFilterApply,
  filterProps,
  searchQuery,
  setQuery,
  languageFilter,
  setLanguageFilter,
  screenWidth,
  clearAll,
  clearQuery,
  isSearchActive,
  isFilterActive,
  setIsSearchActive,
  setIsFilterActive,
  setFilter,
  filter,
  closeFilters,
  setCloseFilters,
  applyFilterText,
  cancelText,
  clearAllText,
  selectedText,
  seeMoreText,
  seeLessText,
  singleFilter = false,
}) {
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [seeMore, setSeeMore] = useState([]);
  const [advancedFilter, setAdvancedFilters] = useState([]);
  const [selectedLocationFilter, setSelectedLocationFilter] = useState({});
  const [parentLocations, setParentLocations] = useState({});
  const router = useRouter();
  // const [parentLocations, setParentLocations] = useState(
  //     {
  //         id: '274',
  //         name: "Regional Locations",
  //         description: ''
  //     }
  // );

  useEffect(() => {
    if (router.isReady && !router.query.category && !router.query.type) {
      setAdvancedOpen(router.asPath.includes('/resources'));
    }
    // }, [router.isReady, router.query.category, router.query.type, router.asPath]);
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }

    let id;

    for (let item of data) {
      if (item?.description?.includes('Locations')) {
        // if(item?.description?.includes("Regions")){
        id = item?.id;
      }
    }

    let obj = {
      id: id,
      name: 'Metros',
      description: '',
    };

    setParentLocations(obj);
  }, []);

  useEffect(() => {
    setAdvancedFilters(filterProps);
  }, [filterProps]);

  useEffect(() => {
    if (advancedOpen) {
      setAdvancedOpen(!advancedOpen);
    }
  }, [searchQuery]);

  const renderSelectedFilters = () => {
    return advancedFilter.map((item, index) => {
      console.log(item);
      return (
        <div
          key={index}
          className={`selected-category ${getContinentColorByName(item?.field_continent ? item.field_continent : item.name)}`}
        >
          <div>{item.name.replace('&amp;', '&')}</div>
          <span
            onClick={() => removeFilter(undefined, item.id, item)}
            className={'escape'}
          >
            ✕
          </span>
        </div>
      );
    });
  };

  const renderParents = (data) => {
    if (!data) {
      return '';
    }

    let send = [];
    let result = [];

    data.forEach((parent) => {
      send.push(
        <div className={'parent-container'} key={parent.id}>
          <span className={'parent-name'}>
            {parent?.name.replace('&#039;', "'")}
          </span>
          <div className={'horizontal-line'} />
          <div
            className={
              isIncludedMore(parent?.id)
                ? 'children-container active'
                : 'children-container'
            }
          >
            {renderChildren(parent, parent?.children)}
          </div>
          {doesParentHaveMoreThanAllowed(parent?.id) && (
            <div
              onClick={() => handleSeeMore(parent?.id)}
              className={seeMore.includes(parent?.id) ? 'see-less' : 'see-more'}
            >
              {seeMore.includes(parent?.id) ? seeLessText : seeMoreText}
            </div>
          )}
        </div>
      );

      if (parent?.id === '274' && selectedLocationFilter !== undefined) {
        if (
          selectedLocationFilter?.children !== undefined &&
          selectedLocationFilter?.children.length > 0
        ) {
          selectedLocationFilter?.children.forEach((child) => {
            if (child?.children !== undefined) {
              child.children.forEach((item) => {
                result.push(item);
              });
            }
          });
        }
      }
    });

    if (!isEmpty(selectedLocationFilter)) {
      send.splice(
        1,
        0,
        <div className={'parent-container'} key={parentLocations.id}>
          <span className={'parent-name'}>{parentLocations?.name}</span>
          <div className={'horizontal-line'} />
          <div
            className={
              isIncludedMore(parentLocations?.id)
                ? 'children-container active'
                : 'children-container'
            }
          >
            {renderGrandchildren(parentLocations, result)}
          </div>
          {result.length > 5 && (
            <div
              onClick={() => handleSeeMore(parentLocations?.id)}
              className={
                seeMore.includes(parentLocations?.id) ? 'see-less' : 'see-more'
              }
            >
              {seeMore.includes(parentLocations?.id)
                ? seeLessText
                : seeMoreText}
            </div>
          )}
        </div>
      );
    }

    return send;
  };

  const doesParentHaveMoreThanAllowed = (id) => {
    return (
      data.filter((item) => item?.id === id && item?.children?.length >= 5)
        .length > 0
    );
  };

  const handleSeeMore = (id) => {
    let more = Object.assign([], seeMore);
    let isIn = isIncludedMore(id);

    if (isIn) {
      removeMore(more, id);
      return;
    }

    more.push(id);
    setSeeMore(more);
  };

  const isIncluded = (id) => {
    return advancedFilter.filter((item) => item?.id === id).length;
  };

  const isLocationIncluded = (id) => {
    return advancedFilter.filter((item) => item?.parent_id === id).length;
  };

  const isIncludedMore = (id) => {
    return seeMore.filter((item) => item === id).length;
  };

  const removeMore = (more, id) => {
    if (!more) {
      more = Object.assign([], seeMore);
    }

    let removeIndex = more.map((item) => item).indexOf(id);
    more.splice(removeIndex, 1);
    setSeeMore(more);
  };

  const handleSelect = (parent, child) => {
    let advanced = Object.assign([], advancedFilter);
    let isIn = isIncluded(child?.id);
    let isLocationIn = isLocationIncluded(child?.parent_id);
    let url = '';
    const paramType = checkFilterType(child);

    if (typeof window !== undefined) {
      url = window.location.pathname;
    }

    if (isLocationIn && child?.parent_id === '274') {
      // removeLocationsFilter(advanced, child.parent_id)
      removeLocationsFilter(advanced, parent?.id);
      // return
    }

    if (isIn) {
      removeFilter(advanced, child?.id, child);
      return;
    }

    if (parent.description.length !== 0) {
      setLanguageFilter([...languageFilter, child]);
    }

    // if(advanced.length === 0){
    if (paramType === 'type' && singleFilter) {
      updateTypeQueryParam(router, url, child?.name);
    } else {
      addQueryParamWithoutRedirecting(
        router,
        url,
        child?.name,
        checkFilterType(child)
      );
    }
    // }

    advanced.push(child);
    setAdvancedFilters(advanced);
    triggerFilter(advanced);
    setIsSearchActive(false);
    setIsFilterActive(true);

    if (child?.parent_id === '274') {
      setSelectedLocationFilter(child);
    }
  };

  const updateTypeQueryParam = (router, url, queryParamType) => {
    const queryString = window.location.search;
    let params = [];

    if (queryString) {
      const paramPairs = queryString.slice(1).split('&');

      for (const pair of paramPairs) {
        const [name, value] = pair.split('=');

        if (name !== 'type') {
          params.push({ name, value });
        }
      }
    }

    params.push({ name: 'type', value: queryParamType.toLowerCase() });

    const newQueryString = params
      .map(
        ({ name, value }) =>
          `${name}=${value.replace(/&/g, '%26').replace(/ /g, '+')}`
      )
      .join('&');

    router.push(
      {
        pathname: url,
        query: newQueryString,
      },
      undefined,
      { shallow: true }
    );
  };

  const checkFilterType = (child) => {
    for (let parent of data) {
      return parent?.id === child?.parent_id ? 'type' : 'category';
    }
  };

  const removeFilter = (advanced, childId, child) => {
    if (!advanced) {
      advanced = Object.assign([], advancedFilter);
    }

    let removeIndex = advanced.map((item) => item?.id).indexOf(childId);
    advanced.splice(removeIndex, 1);
    setAdvancedFilters(advanced);
    triggerFilter(advanced);

    // if(advanced.length === 0){
    removeQueryParam(router, child);
    // }

    if (filter) {
      setFilter(!filter);
    }
  };

  const removeLocationsFilter = (advanced, parentId) => {
    if (!advanced) {
      advanced = Object.assign([], advancedFilter);
    }

    setSelectedLocationFilter({});

    let removeIndex = advanced.map((item) => item?.parent_id).indexOf(parentId);
    advanced.splice(removeIndex, 1);
    setAdvancedFilters(advanced);
    triggerFilter(advanced);
  };

  const renderChildren = (parent, children) => {
    if (!children) {
      return;
    }

    let send = [];

    children
      .sort((a, b) => (Number(a.weight) > Number(b.weight) ? 1 : -1))
      .forEach((child) => {
        send.push(
          <div
            key={child.id}
            className={isIncluded(child.id) ? 'child active' : 'child'}
            onClick={() => handleSelect(parent, child)}
          >
            <span>{child.name.replace('&amp;', '&')}</span>
            <span
              onClick={() => removeFilter(undefined, child.id, child)}
              className={isIncluded(child.id) ? 'escape active' : 'escape'}
            >
              ✕
            </span>
          </div>
        );
      });

    return send;
  };

  // const renderChildren = (parent, children) => {
  //     if (!children) {
  //         return;
  //     }

  //     let send = [];

  //     children.sort((a, b) => (Number(a.weight) > Number(b.weight)) ? 1 : -1).forEach(child => {
  //         const truncatedName = child.name.replace('&amp;', '&');
  //         const displayName = truncatedName.length > 23 ? truncatedName.slice(0, 23) + '...' : truncatedName;

  //         send.push(
  //             <div
  //                 key={child.id}
  //                 className={isIncluded(child.id) ? 'child active' : 'child'}
  //                 onClick={() => handleSelect(parent, child)}
  //             >
  //                 <span>{displayName}</span>
  //                 <span
  //                     onClick={() => removeFilter(undefined, child.id, child)}
  //                     className={isIncluded(child.id) ? 'escape active' : 'escape'}
  //                 >
  //                     ✕
  //                 </span>
  //             </div>
  //         );
  //     });

  //     return send;
  // }

  const renderGrandchildren = (child, grandchildren) => {
    if (!grandchildren) {
      return;
    }

    let send = [];

    if (selectedLocationFilter?.parent_id === child?.id) {
      grandchildren.forEach((grandchild) => {
        send.push(
          <div
            key={grandchild?.id}
            className={isIncluded(grandchild.id) ? 'child active' : 'child'}
            onClick={() => handleSelect(child, grandchild)}
          >
            <span>{grandchild?.name}</span>
            <span
              onClick={() =>
                removeFilter(undefined, grandchild?.id, grandchild)
              }
              className={
                isIncluded(grandchild?.id) ? 'escape active' : 'escape'
              }
            >
              ✕
            </span>
          </div>
        );
      });
    }

    return send;
  };

  const handleHorizontalScroll = () => {
    if (typeof window === 'undefined') {
      return;
    }

    let container = document.querySelector('.selected-categories');
    const first = document.querySelector(
      '.selected-categories .selected-category'
    );

    if (!first) {
      return;
    }

    if (isElementInViewport(first)) {
      container.appendChild(first);
      container.scrollTo(container.scrollLeft - first.offsetWidth, 0);
    }
  };

  const isElementInViewport = (el) => {
    let rect = el.getBoundingClientRect();
    return rect.right > 0;
  };

  const renderFilterResourcesDropdown = () => {
    if (screenWidth < 992) {
      return (
        <>
          {advancedOpen > 0 && <div className={'help-black'} />}
          <img src={'/images/filter-icon.svg'} alt={'filter'} />
        </>
      );
    } else {
      return (
        <>
          <span>{title}</span>
          {advancedOpen > 0 && <div className={'help-black'} />}
          <img src={'/images/arrow-down.svg'} alt={'arrow'} />
        </>
      );
    }
  };

  const getInputValueClass = () => {
    return searchQuery.length !== 0 ? ' focus' : ' ';
  };

  return (
    <div className={'resource-filter'}>
      <div className={'base-filter'}>
        <div className={'inner-container'}>
          <div className={'filter-container flex relative'}>
            {!hideFilter && (
              <div
                className={advancedOpen ? 'left active' : 'left'}
                onClick={() => {
                  setAdvancedOpen(!advancedOpen);
                  setIsSearchActive(false);
                  setIsFilterActive(true);
                }}
              >
                {renderFilterResourcesDropdown()}
              </div>
            )}
            <div className={'right'}>
              <ResourceSearch
                setIsFilterActive={setIsFilterActive}
                setIsSearchActive={setIsSearchActive}
                hideFilter={hideFilter}
                placeholder={search}
                onSearchChange={onSearchChange}
                searchQuery={searchQuery}
              />
              {advancedFilter?.length > 0 && (
                <div className={'selected-container'}>
                  {
                    // advancedFilter.length > 0 &&
                    <span className={'selected'}>
                      {selectedText ? `${selectedText}:` : 'Selected:'}{' '}
                    </span>
                  }
                  <div className={'selected-categories'}>
                    {renderSelectedFilters()}
                  </div>
                  {
                    // advancedFilter.length > 0 &&
                    <div className={'selected-categories-box-shadow'} />
                  }

                  <div
                    className={`clear-all-container ${isSearchActive && !isFilterActive ? 'not-active' : ''}`}
                  >
                    {advancedFilter.length > 1 && (
                      <img
                        className={'scroll-arrow'}
                        src={'/images/arrow-right.svg'}
                        onClick={() => handleHorizontalScroll()}
                        alt={'scroll-arrow'}
                      />
                    )}
                    {advancedFilter.length > 0 && (
                      <span
                        onClick={() => {
                          setAdvancedFilters([]);
                          triggerFilter([]);
                          setLanguageFilter([]);
                          setSelectedLocationFilter({});
                          removeQueryParam(router);
                        }}
                        className={'clear'}
                      >
                        {clearAllText ? clearAllText : 'Clear All'}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
            <img
              className={`close-icon  ${getInputValueClass()} ${!isSearchActive && isFilterActive ? 'not-active' : ''}`}
              src={'/images/close-icon.svg'}
              alt={'close'}
              onClick={clearQuery}
            />
          </div>
        </div>
      </div>
      <div
        className={advancedOpen ? 'advanced-filter active' : 'advanced-filter'}
      >
        <div className={'inner-container'}>
          <div className={'advanced-filter-container'}>
            {renderParents(data)}
          </div>
        </div>
        <div className={'horizontal-line'} />
        <div className={'inner-container'}>
          <div className={'advanced-filter-buttons'}>
            <div
              className={'cancel'}
              onClick={() => {
                setAdvancedOpen(!advancedOpen);
                setAdvancedFilters([]);
                triggerFilter([]);
                setLanguageFilter([]);
                setSelectedLocationFilter({});
                // smoothScroll()
                smoothScrollToTopOfFilters();
              }}
            >
              {cancelText ? cancelText : 'Cancel'}
            </div>
            <div
              className={'apply-filter'}
              onClick={() => {
                handleFilterApply(true);
                setAdvancedOpen(!advancedOpen);
                // setQuery([])
                // smoothScroll()
                smoothScrollToTopOfFilters();
              }}
            >
              {applyFilterText ? applyFilterText : 'Apply Filter'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
